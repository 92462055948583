import React, { useState } from "react";
import menu1 from "./database/images/menu1.jpg";
import menu2 from "./database/images/menu2.jpg";
import Popup from "./Popup";

const MenuItems: React.FC = () => {
  const [state, setState] = useState<string>("");
  return (
    <>
      <div className="menu_block" id="nocoffee">
        <h2 className="menu_title">Напитки</h2>
        <p className="drink_description">и полное меню</p>
        <div className="menu_items">
          <div className="menu_img">
            <img
              src={menu1}
              alt="menu 1"
              onClick={() => setState("menu1")}
            ></img>
          </div>
          <Popup isOpen={"menu1" === state}>
            <div className="body_popup">
              <div className="menu_items_popup">
                <img src={menu1} alt="menu 1"></img>
                <button
                  className="close_button_menu_item"
                  onClick={() => setState("")}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="42px"
                    viewBox="0 0 24 24"
                    width="42px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                </button>
              </div>
            </div>
          </Popup>
          <div className="menu_img">
            <img
              src={menu2}
              alt="menu 2"
              onClick={() => setState("menu2")}
            ></img>
          </div>
          <Popup isOpen={"menu2" === state}>
            <div className="body_popup">
              <div className="menu_items_popup">
                <img src={menu2} alt="menu 2"></img>
                <button
                  className="close_button_menu_item"
                  onClick={() => setState("")}
                >
                  
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="42px"
                    viewBox="0 0 24 24"
                    width="42px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                  </svg>
                </button>
              </div>
            </div>
          </Popup>
        </div>
      </div>
    </>
  );
};

export default MenuItems;

import React, { useState } from "react";
import "./MenuItem.css";
import Popup from "./Popup";

const MenuItem = ({ item }: any) => {
  const [state, setState] = useState<string>("");
  return (
    <>
      {" "}
      <div className="menu-item">
        {
          <div className="menu-item_img-bg" style={{backgroundImage:`url(${item.img})`}}></div>
      }
      
       <p className="menu-item-title">{item.title}</p>
        <p className="menu-item-price">{item.price}р</p>

        <button
          className="menu-item-button"
          onClick={() => setState(item.title)}
        >
          Подробнее
        </button>
     
      </div>
      <Popup isOpen={item.title === state }><div className="body_popup">
          <div className="content_popup">
            <img
              src={
                item.img
                  ? item.img
                  : "https://kelembagaan.kemnaker.go.id/assets/img/no-image.svg"
              }
              alt={item.title}
              className="popup_img"
            />

            <div className="popup_content">
              <p className="popup_title">{item.title}</p>
              <p className="popup_kbju">
                <div>КБЖУ на 100г.</div>
                {item.kbju}
              </p>
              <p className="popup_description">{item.description}</p>
              <p className="popup_weight">{item.weight}г</p>
              <p className="popup_price">{item.price}р</p>
            </div>
            <button className="close_button" onClick={() => setState("")}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
            </button>
          </div>
        </div></Popup>
    </>
  );
};

export default MenuItem;

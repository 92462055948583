import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from "./database/images/interior/1.jpg";
import image2 from "./database/images/interior/2.jpg";
import image3 from "./database/images/interior/3.jpg";
import image4 from "./database/images/interior/4.jpg";
import image5 from "./database/images/interior/5.jpg";
import image6 from "./database/images/interior/6.jpg";
import image7 from "./database/images/interior/7.jpg";
import image8 from "./database/images/interior/8.jpg";
import "./App.css";


export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2
  };
  const images=[image1,image2,image3,image4,image5,image6,image7,image8]
  return (
    <Slider {...settings}>
     {images.map(i=><div className="slide_box"><img src={i} alt="33" style={{width:"100%"}}/></div>)}
    </Slider>
  );
}
import React from 'react'

interface Props{
    isOpen:boolean;
    children:any;
}
const Popup:React.FC<Props> = ({isOpen,children}) => {
  return (
    <div className={`popup ${isOpen? "open" : ""}`}>
        {children}
      </div>
  )
}

export default Popup
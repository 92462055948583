
import french from "./images/breakfast/french.jpg";
import goland_shrimps from "./images/breakfast/goland_shrimps.jpg";
import goland_fish from "./images/breakfast/goland_fish.jpg";
import english from "./images/breakfast/english.jpg";
import scandinav from "./images/breakfast/scandinav.jpg";
import finnish from "./images/breakfast/finnish.jpg";
import omelette from "./images/breakfast/omelette.jpg";
import hashbrowns_fish from "./images/breakfast/hashbrowns_fish.jpg";
import hashbrowns_bacon from "./images/breakfast/hashbrowns_bacon.jpg";
import waffles from "./images/breakfast/waffles.jpg";
import waffles_fish from "./images/breakfast/waffles_fish.jpg";
import shakshuka from "./images/breakfast/shakshuka.jpg";
import shakshuka_shrimps from "./images/breakfast/shakshuka_shrimps.jpg";
import syrniki from "./images/breakfast/syrniki.jpg";
import syrniki_cherry from "./images/breakfast/syrniki_cherry.jpg";
import pancakes_fish from "./images/breakfast/pancakes_fish.jpg";
import pancakes_caviar from "./images/breakfast/pancakes_caviar.jpg";
import porridge from "./images/breakfast/porridge.jpg";

import salad_chicken from "./images/salads/salad_chicken.jpg";
import salad_fish from "./images/salads/salad_fish.jpg";
import salad_green from "./images/salads/salad_green.jpg";

import sandwich_chicken from "./images/sandwich/sandwich_chicken.jpg";
import sandwich_club from "./images/sandwich/sandwich_club.jpg";
import sandwich_fish from "./images/sandwich/sandwich_fish.jpg";
import sandwich_veg from "./images/sandwich/sandwich_veg.jpg";


import soup_cheese_chicken from "./images/soup/soup_cheese_chicken.jpg";
import soup_cheese_shrimps from "./images/soup/soup_cheese_shrimps.jpg";
import soup_chicken from "./images/soup/soup_chicken.jpg";
import tom_yam from "./images/soup/tom_yam.jpg";

import boul_chicken from "./images/bouls/boul_chicken.jpg";
import boul_fish from "./images/bouls/boul_fish.jpg";
import boul_shrimps from "./images/bouls/boul_shrimps.jpg";

import risotto_carbonara from "./images/main/risotto_carbonara.jpg";
import risotto_mushrooms from "./images/main/risotto_mushrooms.jpg";
import risotto_seafood from "./images/main/risotto_seafood.jpg";

import cheesecake_ny from "./images/deserts/cheesecake_ny.jpg";
import karpatka_pumpkin from "./images/deserts/karpatka_pumpkin.jpg";
import makaroon from "./images/deserts/makaroon.jpg";
import pavlova_blueberry from "./images/deserts/pavlova_blueberry.jpg";
import pavlova_mandarin from "./images/deserts/pavlova_mandarin.jpg";
import pistachio_cheesecake from "./images/deserts/pistachio_cheesecake.jpg";
import pistachio_shu from "./images/deserts/pistachio_shu.jpg";
import profiterole from "./images/deserts/profiterole.jpg";
import strawberry_shu from "./images/deserts/strawberry_shu.jpg";
import tartaletka from "./images/deserts/tartaletka.jpg";

import bird_cook_gray from "./images/bird_cook_gray.png";

// import cocoa_l from "./images/nocoffee/cocoa_l.jpg";
// import cocoa_s from "./images/nocoffee/cocoa_s.jpg";
// import latte_matcha_l from "./images/nocoffee/latte_matcha_l.jpg";
// import latte_matcha_s from "./images/nocoffee/latte_matcha_s.jpg";
// import matcha_flower from "./images/nocoffee/matcha_flower.jpg";
// import orange_fresh from "./images/nocoffee/orange_fresh.jpg";

// import black_tea from "./images/tea/black_tea.jpg";
// import green_tea from "./images/tea/green_tea.jpg";


// import icetea_kiwi from "./images/lemonades/icetea_kiwi.jpg";
// import icetea_mango from "./images/lemonades/icetea_mango.jpg";
// import lemonade_classic from "./images/lemonades/lemonade_classic.jpg";
// import lemonade_currant from "./images/lemonades/lemonade_currant.jpg";
// import mango_lemonade from "./images/lemonades/mango_lemonade.jpg";


// import americano_s from "./images/coffee/americano_s.jpg";
// import americano_l from "./images/coffee/americano_l.jpg";
// import cappuccino_l from "./images/coffee/cappuccino_l.jpg";
// import cappuccino_s from "./images/coffee/cappuccino_s.jpg";
// import coffee_filter from "./images/coffee/coffee_filter.jpg";
// import flat_white from "./images/coffee/flat_white.jpg";
// import latte_m from "./images/coffee/latte_m.jpg";
// import latte_pistachio_l from "./images/coffee/latte_pistachio_l.jpg";
// import latte_pistachio_s from "./images/coffee/latte_pistachio_s.jpg";
// import latte_s from "./images/coffee/latte_s.jpg";
// import latte_singapore_l from "./images/coffee/latte_singapore_l.jpg";
// import latte_singapore_s from "./images/coffee/latte_singapore_s.jpg";
// import raph_flower_l from "./images/coffee/raph_flower_l.jpg";
// import raph_flower_s from "./images/coffee/raph_flower_s.jpg";
// import raph_l from "./images/coffee/raph_l.jpg";
// import raph_s from "./images/coffee/raph_s.jpg";
// import raph_orange_s from "./images/coffee/raph_orange_s.jpg";
// import raph_orange_l from "./images/coffee/raph_orange_l.jpg";

export const content={
    "breakfast":[
        {
            "title":"Французский завтрак",
            "description":"Воздушный омлет, круассан, ветчина, сливочно-чесночное масло, мини шпинат",
            "weight":"290",
            "price":"690",
            "img":french,
            "kbju":"белки - 11,9 г, жиры - 15,4 г, углеводы - 6,1 г, ккал - 210,7"
        },
        {
            "title":"Голландский завтрак с креветками",
            "description":"Хлеб тартин, творожный сыр, авокадо, яйцо пашот, мини шпинат, креветки, сливочный соус, микрозелень",
            "weight":"318",
            "price":"750",
            "img":goland_shrimps,
            "kbju":"белки - 8,6 г, жиры - 12,7 г, углеводы - 2,3 г, ккал - 157,9"
        },
        {
            "title":"Голландский завтрак с лососем",
            "description":"Хлеб тартин, творожный сыр, авокадо, яйцо пашот, мини шпинат, слиточный соус, микрозелень, лосось слабой соли",
            "weight":"295",
            "price":"750",
            "img":goland_fish,
            "kbju":"белки - 8,4 г, жиры - 14,6 г, углеводы - 2,8 г, ккал - 176,5"
        },
        {
            "title":"Английский завтрак",
            "description":"Хлеб тартин, глазунья, белая фасоль в томатном соусе, картофель, шампиньоны, куриные колбаски, бекон, томаты, кетчуп",
            "weight":"560",
            "price":"690",
            "img": english,
            "kbju":"белки - 5,9 г, жиры - 6,0 г, углеводы - 12,7 г, ккал - 127,7"
           
        },
        {
            "title":"Скандинавский завтрак",
            "description":"Хлеб тартин, классический омлет с моцарела, лосось слабой соли, паштет из пармезана, мини шпинат",
            "weight":"375",
            "price":"750",
            "img": scandinav,
            "kbju":"белки - 10,3 г, жиры - 10,2 г, углеводы - 10,9 г, ккал - 176,4"
            
        },
        {
            "title":"Финский завтрак",
            "description":"Хлеб тартин, сливочный скрэмбл, мини шпинат, руккола, томаты черри, лосось слабой соли",
            "weight":"380",
            "price":"690",
            "img": finnish,
            "kbju":"белки - 8,8 г, жиры - 13,6 г, углеводы - 10,7 г, ккал - 200,5"
        },
        {
            "title":"Омлет четыре сыра",
            "description":"Воздушный омлет, горгонзола, Чеддер, моцарелла, пармезан",
            "weight":"320",
            "price":"430",
            "img": omelette,
            "kbju":"белки - 11,8 г, жиры - 9,9 г, углеводы - 1,5 г, ккал - 142,2"
        },
        {
            "title":"Драники с лососем",
            "description":"Картофельные драники, яйцо пашот, сметана, лосось слабой соли",
            "weight":"285",
            "price":"590",
            "img": hashbrowns_fish,
            "kbju":"белки - 6,5 г, жиры - 6,1 г, углеводы - 6,8 г, ккал - 108,5"
        },
        {
            "title":"Драники с беконом",
            "description":"Картофельные драники, яйцо пашот, сметана, бекон криспи",
            "weight":"325",
            "price":"490",
            "img": hashbrowns_bacon,
            "kbju":"белки - 5,3 г, жиры - 15,7 г, углеводы - 5,7 г, ккал - 185,0"
        },
        {
            "title":"Вафли бенедикт с лососем",
            "description":"Вафля, лосось, пашот,сливочный мусс, мини шпинат, зеленое масло",
            "weight":"245",
            "price":"650",
            "img": waffles_fish,
            "kbju":"белки - 8,3 г, жиры - 16,7 г, углеводы - 10,5 г, ккал - 225,2"
        },
        {
            "title":"Вафли сладкие со свежими ягодами",
            "description":"Венские вафли, соленая карамель, мусс вареная сгущенка, голубика, лепестки миндаля",
            "weight":"330",
            "price":"550",
            "img": waffles,
            "kbju":"белки - 5,3 г, жиры - 16,5 г, углеводы - 22,7 г, ккал - 260,9"
        },
        {
            "title":"Шакшука классическая",
            "description":"Хлеб тартин, яйца, лук репчатый, томатный соус, кинза, микрозелень",
            "weight":"340",
            "price":"450",
            "img": shakshuka,
            "kbju":"белки - 6,3 г, жиры - 5,9 г, углеводы - 9,5 г, ккал - 115,8"
        },
        {
            "title":"Шакшука с цыпленком",
            "description":"Хлеб тартин, яйца,лук репчатый, томатный соус, кинза, микрозелень, филе цыпленка",
            "weight":"390",
            "price":"550",
            "img": shakshuka,
            "kbju":"белки - 8,2 г, жиры - 5,3 г, углеводы - 8,3 г, ккал - 113,6"
        },
        {
            "title":"Шакшука с креветками",
            "description":"Хлеб тартин, яйца, лук репчатый, томатный соус, кинза, микрозелень, креветки",
            "weight":"390",
            "price":"590",
            "img": shakshuka_shrimps,
            "kbju":"белки - 7,6 г, жиры - 5,6 г, углеводы - 8,3 г, ккал - 113,9"
        },
        {
            "title":"Сырники соленая карамель",
            "description":"Творожные сырники, домашняя соленая карамель, мусс из вареной сгущенки, лепестки миндаля ",
            "weight":"220",
            "price":"430",
            "img": syrniki,
            "kbju":"белки - 8,3 г, жиры - 8,6 г, углеводы - 18,5 г, ккал - 184,3"
        },
        {
            "title":"Сырники с вишней",
            "description":"Творожные сырники, вишневое варенье, сметана",
            "weight":"220",
            "price":"430",
            "img": syrniki_cherry,
            "kbju":"белки - 7,0 г, жиры - 5,7 г, углеводы - 21,3 г, ккал - 164,7"
        },
        // {
        //     "title":"Блинчики с лососем",
        //     "description":"Блинчики, соус голландский, лосось с/с, масло зеленое",
        //     "weight":"210",
        //     "price":"450",
        //     "img": pancakes_fish,
        //     "kbju":"белки - 7,1 г, жиры - 17,8 г, углеводы - 20,6 г, ккал - 270,9"
        // },
        // {
        //     "title":"Блинчики красная икра",
        //     "description":"Блинчики, сметана, икра красная, масло зеленое",
        //     "weight":"215",
        //     "price":"450",
        //     "img": pancakes_caviar,
        //     "kbju":"белки - 6,9 г, жиры - 16,2 г, углеводы - 20,0 г, ккал - 253,4"
        // },
        {
            "title":"Каша с чиа овсяная",
            "description":"Каша, молоко, семена чиа, голубика, лепестки миндаля, мусс вареная сгущенка",
            "weight":"300",
            "price":"350",
            "img": porridge,
            "kbju":"белки - 3,8 г, жиры - 16,3 г, углеводы - 13,9 г, ккал - 217,9",
        },
        {
            "title":"Каша с чиа манная",
            "description":"Каша, молоко, семена чиа, голубика, лепестки миндаля, мусс вареная сгущенка",
            "weight":"300",
            "price":"350",
            "img": porridge,
            "kbju":"белки - 3,8 г, жиры - 16,3 г, углеводы - 13,9 г, ккал - 217,9"
        },
        {
            "title":"Каша с чиа рисовая",
            "description":"Каша, молоко, семена чиа, голубика, лепестки миндаля, мусс вареная сгущенка",
            "weight":"300",
            "price":"350",
            "img": porridge,
            "kbju":"белки - 3,8 г, жиры - 16,3 г, углеводы - 13,9 г, ккал - 217,9"
        },     
    ],
    "salads":[
        {
            "title":"Салат с цыпленком",
            "description":"Мини шпинат, томаты черри, филе цыпленка, кунжутный соус, кунжут, бобы эдамаме, крутоны",
            "weight":"200",
            "price":"490",
            "img": salad_chicken,
            "kbju":"белки - 12,2 г, жиры - 1,6 г, углеводы - 14,8 г, ккал - 122,3"
        },
        {
            "title":"Салат с тунцом и яйцом пашот",
            "description":"Мини шпинат, татаки из тунца, яйцо пашот, заправка на основе лайма и меда, кунжут",
            "weight":"150",
            "price":"490",
            "img": salad_fish,
            "kbju":"белки - 11,4 г, жиры - 12,1 г, углеводы - 2,5 г, ккал - 164,3"
        },
        {
            "title":"Зеленый салат с авокадо",
            "description":"Половинка авокадо, мини шпинат, бобы Эдамаме, чука, лепестки миндаля, заправка на основе лайма и меда, кунжут",
            "weight":"195",
            "price":"490",
            "img": salad_green,
            "kbju":"белки - 4,1 г, жиры - 16,4 г, углеводы - 7,7 г, ккал - 195,0"
        }
    ],
    "sandwich":[
        {
            "title":"Сэндвич цыпленок-терияки",
            "description":"Молочный хлеб, цыпленок су-вид, шампиньоны, томаты, мини шпинат, моцарелла",
            "weight":"285",
            "price":"460",
            "img": sandwich_chicken,
            "kbju":"белки - 8,8 г, жиры - 6,5 г, углеводы - 22,1 г, ккал - 181,7"
        },
        // {
        //     "title":"Тост баклажаном",
        //     "description":"Хлеб тартин, томленый баклажан, сыр халуми, кинза, микрозелень, зеленое масло, чеснок",
        //     "weight":"228",
        //     "price":"400",
        //     "img": sandwich_veg,
        //     "kbju":"белки - 5,6 г, жиры - 5,4 г, углеводы - 15,8 г, ккал - 133,6"
        // },
        {
            "title":"Клаб-сэндвич",
            "description":"Молочный хлеб, филе цыпленка, криспи бекон, яйцо, томаты, зелень, майонез, моцарелла",
            "weight":"440",
            "price":"590",
            "img": sandwich_club,
            "kbju":"белки - 7,5 г, жиры - 12,1 г, углеводы - 15,3 г, ккал - 200,2"
        },
        {
            "title":"Авокадо-тост с томатами",
            "description":"Молочный хлеб, соус песто из зелени, авокадо, томаты Парадайс, рукола, наршараб",
            "weight":"440",
            "price":"490",
            "img": bird_cook_gray,
            "kbju":""
        },
        {
            "title":"Круассан с ветчиной и сыром",
            "description":"Круассан, ветчина, шампиньоны, сыр Чеддер, романно, сливочный соус",
            "weight":"440",
            "price":"490",
            "img": bird_cook_gray,
            "kbju":""
        },
        {
            "title":"Круассан с лососем и сливочным соусом",
            "description":"Круассан, лосось слабой соли, романно, яйцо, сливочный соус",
            "weight":"440",
            "price":"590",
            "img": bird_cook_gray,
            "kbju":""
        },
        // {
        //     "title":"Сэндвич лосось-гуакамоле",
        //     "description":"Молочный хлеб, творожный сыр, лосось слабой соли, гуакамоле, мини шпинат",
        //     "weight":"190",
        //     "price":"650",
        //     "img": sandwich_fish,
        //     "kbju":"белки - 6,4 г, жиры - 8,0 г, углеводы - 20,2 г, ккал - 178,4"
        // },
    ],
    "soups":[
        // {
        //     "title":"Консоме с цыпленком",
        //     "description":"Куриный бульон, филе цыпленка, лапша, отварное яйцо, зелень, зеленое масло",
        //     "weight":"445",
        //     "price":"390",
        //     "img": soup_chicken,
        //     "kbju":"белки - 4,9 г, жиры - 3,1 г, углеводы - 12,7 г, ккал - 98,6"
        // },
        {
            "title":"Сырный суп с цыпленком",
            "description":"Куриный бульон, картофель, морковь, сыр хохланд, филе цыпленка, крутоны, кунжут, микрозелень",
            "weight":"345",
            "price":"390",
            "img": soup_cheese_chicken,
            "kbju":"белки - 7,5 г, жиры - 7,7 г, углеводы - 7,0 г, ккал - 127,3"
        },
        {
            "title":"Сырный суп с креветками",
            "description":"Куриный бульон, картофель, морковь, сыр хохланд, креветки, крутоны, кунжут, микрозелень",
            "weight":"350",
            "price":"550",
            "img": soup_cheese_shrimps,
            "kbju":"белки - 7,6 г, жиры - 8,0 г, углеводы - 6,9 г, ккал - 129,8"
        },
        {
            "title":"Том-ям",
            "description":"Бульон рыбный, креветки, мидия, черри, кальмар, кокосовое молоко, паста Том Ям, перец, шампиньоны, кинза, булочка бао, рис, кунжут, микрозелень",
            "weight":"470",
            "price":"590",
            "img": tom_yam,
            "kbju":"белки - 5,6 г, жиры - 3,7 г, углеводы - 21,9 г, ккал - 142,7"
        },
        
    ],
    "bouls":[
        {
            "title":"Боул с овощами",
            "description":"Киноа, авокадо, черри, бобы эдамаме, чука, огурец, редис, соевый соус, кунжутное масло",
            "weight":"330",
            "price":"490",
            "img": bird_cook_gray,
            "kbju":""
        },
        {
            "title":"Боул с цыпленком",
            "description":"Авокадо, киноа, черри, бобы эдамаме, яйцо пашот, соус унаги, филе цыпленка",
            "weight":"330",
            "price":"550",
            "img": boul_chicken,
            "kbju":"белки - 12,5 г, жиры - 5,2 г, углеводы - 31,3 г, ккал - 221,8"
        },
        {
            "title":"Боул с креветками",
            "description":"Авокадо, киноа, черри, бобы эдамаме, яйцо пашот, соус унаги, креветки",
            "weight":"330",
            "price":"590",
            "img": boul_shrimps,
            "kbju":"белки - 11,6 г, жиры - 5,9 г, углеводы - 30,5 г, ккал - 221,8"
        },
        {
            "title":"Боул с тунцом",
            "description":"Авокадо, киноа, черри, бобы эдамаме, яйцо пашот, соус унаги, татаки из тунца",
            "weight":"270",
            "price":"590",
            "img": boul_fish,
            "kbju":"белки - 12,0 г, жиры - 4,1 г, углеводы - 38,1 г, ккал - 236,8"
        },
        
    ],
    "main":[
        {
            "title":"Ризотто с белыми грибами",
            "description":"Рис, сливки, белые грибы, лук репчатый, криспи, лук, микрозелень",
            "weight":"270",
            "price":"550",
            "img": risotto_mushrooms,
            "kbju":"белки - 6,2 г, жиры - 8,8 г, углеводы - 43,4 г, ккал - 277,9"
        },
        {
            "title":"Ризотто с морепродуктами",
            "description":"Рис, креветки, кальмар, сливки, пармезан, соус сладкий чили, соус манго чили, микрозелень",
            "weight":"310",
            "price":"590",
            "img": risotto_seafood,
            "kbju":"белки - 8,4 г, жиры - 7,6 г, углеводы - 35,0 г, ккал - 241,8"
        },
        {
            "title":"Птитим Карбонара",
            "description":"Птитим, сливки, бекон, желток, сыр пармезан, лук фри, микрозелень",
            "weight":"300",
            "price":"530",
            "img": risotto_carbonara,
            "kbju":"белки - 10,7 г, жиры - 16,8 г, углеводы - 33,4 г, ккал - 327,2"
        },
        {
            "title":"Зеленая гречка с брынзой и томатами",
            "description":"Зеленая гречка, томаты Парадайс, песто из зелени, брынза, пармезан",
            "weight":"300",
            "price":"530",
            "img": bird_cook_gray,
            "kbju":""
        },
        
    ],
    "deserts":[
        {
            "title":"Карпатка с тыквой",
            "description":"Тесто заварное, миндальные лепестки, крем заварной с тыквой, голубика, гель нейтральный, сахарная пудра",
            "weight":"130",
            "price":"380",
            "img": karpatka_pumpkin,
            "kbju":"белки - 5,7 г, жиры - 12,5 г, углеводы - 19,9 г, ккал - 215,6"
        },
        {
            "title":"Макарон (модификатор на выбор: фисташка, черника-лаванда, малина, пломбир, лимон, шоколад)",
            "description":"Миндальная мука, яйцо, сахар, ганаш",
            "weight":"38",
            "price":"130",
            "img": makaroon,
            "kbju":`фисташка: белки - 5,8 г, жиры - 10,4 г, углеводы - 34,2 г, ккал - 254,0
            черника-лаванда: белки - 5,2 г, жиры - 9,3 г, углеводы - 30,5  -, ккал: 226,5
            малина: белки - 6,6 г, жиры - 20,0 г, углеводы - 40,5 г, ккал - 368,5
            пломбир: белки - 7,6 г, жиры - 20,2 г, углеводы - 42,3 г, ккал - 381,1
            лимон: белки - 7,3 г, жиры - 21,6 г, углеводы - 42,1 г, ккал - 391,4
            шоколад: белки - 7,1 г, жиры - 22,4 г, углеводы - 39,8 г, ккал - 388,8`
        },
        {
            "title":"Павлова с голубикой",
            "description":"Безе, кули черная смородина, крем чиз, голубика, гель нейтральный",
            "weight":"190",
            "price":"420",
            "img": pavlova_blueberry,
            "kbju":"белки - 8,6 г, жиры - 12,8 г, углеводы - 27,3 г, ккал - 259,5"
        },
        {
            "title":"Павлова с мандаринами",
            "description":"Безе, кули мандарина, крем чиз, мандарин, сахарная пудра, розмарин",
            "weight":"195",
            "price":"420",
            "img": pavlova_mandarin,
            "kbju":"белки - 8,4 г, жиры - 14,5 г, углеводы - 25,6 г, ккал - 266,1"
        },
        {
            "title":"Профитроль",
            "description":"Заварное тесто, заварной крем с ванилью",
            "weight":"78",
            "price":"120",
            "img": profiterole,
            "kbju":"белки - 5,3 г, жиры - 11,6 г, углеводы - 19,4 г, ккал - 203,4"
        },
        {
            "title":"Тарталетка лимонная",
            "description":"Песочное тесто, лимонный курд, меренга",
            "weight":"95",
            "price":"420",
            "img": tartaletka,
            "kbju":"белки - 4,9 г, жиры - 21,2 г, углеводы - 43,1 г, ккал - 383,0",
        },
        {
            "title":"Чизкейк Нью-Йорк",
            "description":"Сыр творожный, сахар, яйцо, сливки, печенье, масло сливочное, лимон",
            "weight":"130",
            "price":"380",
            "img": cheesecake_ny,
            "kbju":"белки - 10,7 г, жиры - 21,7 г, углеводы - 17,8 г, ккал - 309,3"
        },
        {
            "title":"Чизкейк фисташковый",
            "description":"Сыр творожный, сахар, яйцо, сливки, печенье, масло сливочное, фисташковая паста, лимон",
            "weight":"120",
            "price":"380",
            "img": pistachio_cheesecake,
            "kbju":"белки - 12,4 г, жиры - 24,8 г, углеводы - 16,0 г, ккал - 336,4"
        },
        {
            "title":"Шу клубничный чизкейк",
            "description":"Тесто заварное, крем чиз клубничный, ганаш клубничный, клубника, гель нейтральный",
            "weight":"120",
            "price":"320",
            "img":  strawberry_shu,
            "kbju":"белки - 8,6 г, жиры - 16,5 г, углеводы - 19,0 г, ккал - 259,5"
        },
        {
            "title":"Шу фисташка-малина",
            "description":"Заварное тесто, заварной фисташковый крем, ганаш малиновый, клубника, гель нейтральный, фисташка",
            "weight":"112",
            "price":"320",
            "img": pistachio_shu,
            "kbju":"белки - 5,0 г, жиры - 14,7 г, углеводы - 18,2 г, ккал - 224,7"
        },
        
    ],
    // "nocoffee":[
    //     {
    //         "title":"Апельсиновый фреш",
    //         "description":"Апельсины",
    //         "weight":"200",
    //         "price":"300",
    //         "img": orange_fresh,
    //         "kbju":"белки - 0,8 г, жиры - 0,0 г, углеводы - 8,6 г, ккал - 37,6"
    //     },
        
    //     {
    //         "title":"Какао маленький",
    //         "description":"Какао порошок, молоко",
    //         "weight":"340",
    //         "price":"350",
    //         "img": cocoa_s,
    //         "kbju":"белки - 2,4 г, жиры - 4,2 г, углеводы - 14,5 г, ккал - 104,6"
    //     },
    //     {
    //         "title":"Какао большой",
    //         "description":"Какао порошок, молоко",
    //         "weight":"470",
    //         "price":"450",
    //         "img": cocoa_l,
    //         "kbju":"белки - 2,4 г, жиры - 4,2 г, углеводы - 14,5 г, ккал - 104,6"
    //     },
    //     {
    //         "title":"Латте Матча маленький",
    //         "description":"Матча, молоко",
    //         "weight":"310",
    //         "price":"350",
    //         "img": latte_matcha_s,
    //         "kbju":"белки - 2,6 г, жиры - 2,6 г, углеводы - 4,0 г, ккал - 49,9"
    //     },
    //     {
    //         "title":"Латте Матча большой",
    //         "description":"Матча, молоко",
    //         "weight":"470",
    //         "price":"450",
    //         "img": latte_matcha_l,
    //         "kbju":"белки - 2,4 г, жиры - 4,2 г, углеводы - 14,5 г, ккал - 104,6"
    //     },
    //     {
    //         "title":"Матча Фиалка",
    //         "description":"Матча голубая, цветочный сироп, кокосовое молоко, лимон",
    //         "weight":"470",
    //         "price":"400",
    //         "img": matcha_flower,
    //         "kbju":"белки - 0,8 г, жиры - 0,7 г, углеводы - 3,5 г, ккал - 23,6"
    //     },
    // ],
    // "coffee":[
    //     {
    //         "title":"Американо маленький",
    //         "description":"Эспрессо, вода",
    //         "weight":"200",
    //         "price":"220",
    //         "img": americano_s,
    //         "kbju":"белки - 0,7 г, жиры - 0,7 г, углеводы - 6,5 г, ккал - 36,5"
    //     },
    //     {
    //         "title":"Американо большой",
    //         "description":"Двойной эспрессо, вода",
    //         "weight":"300",
    //         "price":"250",
    //         "img": americano_l,
    //         "kbju":"белки - 0,7 г, жиры - 0,7 г, углеводы - 6,5 г, ккал - 36,5",
            
    //     },
    //     {
    //         "title":"Капучино маленький",
    //         "description":"Эспрессо, молоко",
    //         "weight":"200",
    //         "price":"260",
    //         "img": cappuccino_s,
    //         "kbju":"белки - 3,7 г, жиры - 4,2 г, углеводы - 6,7 г, ккал - 81,7"
    //     },
    //     {
    //         "title":"Капучино большой",
    //         "description":"Молоко, двойной эспрессо",
    //         "weight":"300",
    //         "price":"290",
    //         "img": cappuccino_l,
    //         "kbju":"белки - 4,2 г, жиры - 4,6 г, углеводы - 7,7 г, ккал - 91,9"
    //     },
    //     {
    //         "title":"Латте Сингапур маленький",
    //         "description":"Молоко, эспрессо, карамельно-лимонный соус",
    //         "weight":"310",
    //         "price":"420",
    //         "img": latte_singapore_s,
    //         "kbju":"белки - 3,1 г, жиры - 4,3 г, углеводы - 9,4 г, ккал - 89,1"
    //     },
    //     {
    //         "title":"Латте Сингапур большой",
    //         "description":"Молоко, эспрессо, карамельно-лимонный соус",
    //         "weight":"450",
    //         "price":"470",
    //         "img": latte_singapore_l,
    //         "kbju":"белки - 3,4 г, жиры - 4,4 г, углеводы - 10,4 г, ккал - 94,9",
            
    //     },
    //     {
    //         "title":"Латте Фисташка-Белый шоколад маленький",
    //         "description":"Молоко, эспрессо, соус фисташка-белый шоколад, фисташки",
    //         "weight":"310",
    //         "price":"420",
    //         "img": latte_pistachio_s,
    //         "kbju":"белки - 2,8 г, жиры - 3,1 г, углеводы - 6,3 г, ккал - 64,0"
    //     },
    //     {
    //         "title":"Латте Фисташка-Белый шоколад большой",
    //         "description":"Молоко, двойной эспрессо, соус фисташка-белый шоколад, фисташки",
    //         "weight":"450",
    //         "price":"470",
    //         "img": latte_pistachio_l,
    //         "kbju":"белки - 3,0 г, жиры - 3,4 г, углеводы - 6,9 г, ккал - 69,5"
    //     },
    //     {
    //         "title":"Латте маленький",
    //         "description":"Молоко, эспрессо",
    //         "weight":"310",
    //         "price":"320",
    //         "img": latte_s,
    //         "kbju":"белки - 3,1 г, жиры - 3,5 г, углеводы - 5,4 г, ккал - 66,6"
    //     },
    //     {
    //         "title":"Латте большой",
    //         "description":"Молоко, двойной эспрессо",
    //         "weight":"450",
    //         "price":"370",
    //         "img": latte_m,
    //         "kbju":"белки - 3,1 г, жиры - 3,5 г, углеводы - 5,4 г, ккал - 66,6"
    //     },
    //     {
    //         "title":"Раф классический маленький",
    //         "description":"Сливки, эспрессо, сахарная пудра с ванилью",
    //         "weight":"310",
    //         "price":"390",
    //         "img": raph_s,
    //         "kbju":"белки - 3,6 г, жиры - 10,8 г, углеводы - 8,5 г, ккал - 146,5"
    //     },
    //     {
    //         "title":"Раф классический большой",
    //         "description":"Сливки, двойной эспрессо, сахарная пудра с ванилью",
    //         "weight":"470",
    //         "price":"440",
    //         "img": raph_l,
    //         "kbju":"белки - 3,6 г, жиры - 10,8 г, углеводы - 8,5 г, ккал - 154,4"
    //     },
    //     {
    //         "title":"Раф Цветочный маленький",
    //         "description":"Сливки, эспрессо, цветочный сироп",
    //         "weight":"300",
    //         "price":"470",
    //         "img": raph_flower_s,
    //         "kbju":"белки - 3,6 г, жиры - 9,1 г, углеводы - 10,0 г, ккал - 138,3"
    //     },
    //     {
    //         "title":"Раф Цветочный большой",
    //         "description":"Сливки, двойной эспрессо, цветочный сироп",
    //         "weight":"470",
    //         "price":"520",
    //         "img": raph_flower_l,
    //         "kbju":"белки - 3,9 г, жиры - 9,3 г, углеводы - 10,9 г, ккал - 145,4"
    //     },
    //     {
    //         "title":"Раф Цитрусовый маленький",
    //         "description":"Сливки, эспрессо, цитрусовая пудра с ванилью",
    //         "weight":"300",
    //         "price":"470",
    //         "img": raph_orange_s,
    //         "kbju":"белки - 3,7 г, жиры - 9,6 г, углеводы - 6,3 г, ккал - 129,1",
    //     },
    //     {
    //         "title":"Раф Цитрусовый большой",
    //         "description":"Сливки, двойной эспрессо, цитрусовая пудра с ванилью",
    //         "weight":"450",
    //         "price":"520",
    //         "img": raph_orange_l,
    //         "kbju":"белки - 4,2 г, жиры - 9,8 г, углеводы - 7,6 г, ккал - 139,3"
    //     },
    //     {
    //         "title":"Фильтр кофе",
    //         "description":"Кофе молотый, вода",
    //         "weight":"200",
    //         "price":"250",
    //         "img": coffee_filter,
    //         "kbju":"белки - 0,8 г, жиры - 0,7 г, углеводы - 1,9 г, ккал - 17,2"
    //     },
    //     {
    //         "title":"Флэт Уайт",
    //         "description":"Двойной эспрессо, молоко",
    //         "weight":"200",
    //         "price":"280",
    //         "img": flat_white,
    //         "kbju":"белки - 2,2 г, жиры - 2,4 г, углеводы - 4,0 г, ккал - 48,2",
    //     },
        
    // ],
    // "tea":[
    //     {
    //         "title":"Чай зеленый",
    //         "description":"Чай зеленый, вода",
    //         "weight":"450",
    //         "price":"350",
    //         "img": green_tea,
    //         "kbju":"белки - 0,0 г, жиры - 0,0 г, углеводы - 0,1 г, ккал - 0,3",
    //     },
    //     {
    //         "title":"Чай черный",
    //         "description":"Чай черный, вода",
    //         "weight":"450",
    //         "price":"350",
    //         "img": black_tea,
    //         "kbju":"белки - 0,2 г, жиры - 0,0 г, углеводы - 0,1 г, ккал - 1,3"
    //     },
        
    // ],
    // "lemonades":[
    //     {
    //         "title":"Лимонад Манго-Маракуйя",
    //         "description":"Вода газ, апельсиновый фреш, пюре манго-маракуйя, апельсин",
    //         "weight":"470",
    //         "price":"400",
    //         "img": mango_lemonade,
    //          "kbju":"белки - 0,2 г, жиры - 0,1 г, углеводы - 14,3 г, ккал - 58,6"
    //     },
    //     {
    //         "title":"Лимонад Смородина",
    //         "description":"Вода газ, смородина, пюре смородина, мята, лимон",
    //         "weight":"470",
    //         "price":"400",
    //         "img": lemonade_currant,
    //          "kbju":"белки - 0,1 г, жиры - 0,1 г, углеводы - 14,2 г, ккал - 57,9"
    //     },
    //     {
    //         "title":"Лимонад классический",
    //         "description":"Вода газ, лимон, мята, пюре лимона",
    //         "weight":"470",
    //         "price":"400",
    //         "img": lemonade_classic,
    //          "kbju":"белки - 0,2 г, жиры - 0,0 г, углеводы - 0,7 г, ккал - 3,8"
    //     },
    //     {
    //         "title":"Холодный чай Киви-Фейхоа",
    //         "description":"Зеленый чай, пюре киви-фейхоа, лимон",
    //         "weight":"470",
    //         "price":"400",
    //         "img": icetea_kiwi,
    //          "kbju":"белки - 0,0 г, жиры - 0,0 г, углеводы - 7,5 г, ккал - 31,0"
    //     },
    //     {
    //         "title":"Холодный чай Манго-Ромашка",
    //         "description":"Чай ромашковый, пюре манго-маракуйя, апельсин",
    //         "weight":"470",
    //         "price":"400",
    //         "img": icetea_mango,
    //          "kbju":"белки - 0,1 г, жиры - 0,0 г, углеводы - 8,3 г, ккал - 33,8"
    //     },
       
        
    // ]

  }